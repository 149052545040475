import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'No Side Effects',
    description:
      'Customized Right food is the greatest asset in the Naturopathic healing, in this way it has no side effects',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'No Man-made Supplements',
    description:
      'Naturopathy treatment is natural healing techniques using the healing powers of body through nature.',
    icon: LockClosedIcon,
  },
  {
    name: 'Concentrates on Root causes, Not the disease',
    description:
      'Naturopathy identifies the root causes of ailments and works towards the elimination of their causes.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Completely Natural & healthy',
    description:
      'The right nutritious diet is the key formula for leading an active lifestyle. The greatest methods to stay healthy is to maintain healthy eating choice as guided by the naturopathic experts.',
    icon: FingerPrintIcon,
  },
]

export default function Example() {
  return (
    <div id='benefits' className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-grey-600">Benefits of Core Naturopathy</h2>
          <p className="mt-2 text-3xl font-mono font-bold tracking-tight text-green-900 sm:text-3xl">
          THE  HUMAN  BODY  IS  DESIGNED TO  HEAL
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
