import React, { useEffect, useState } from 'react';
import Header from './Component/Header';
import Aboutnaturopathy from './Component/Aboutnaturopathy';
import Aboutus from './Component/Aboutus';
import Testimonial from './Component/Testimonial';
import Services from './Component/Services';
import Benefits from './Component/Benefits';
import Contactus from './Component/Contactus';
import CTA from './Component/CTA';
import './App.css';  // Global styles

function App() {
  const [showModal, setShowModal] = useState(true);  // State to control modal visibility

  // Close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="App scroll-smooth">
      {/* Modal Pop-Up */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Take our Health Assessment!</h2>
            <p>Get started on your wellness journey by taking a quick health assessment.</p>
            <a href="https://forms.gle/LHoqgBqN3qmRuKvw6" target="_blank" rel="noopener noreferrer">
              <button className="blink-button">Take Assessment</button>
            </a>
            <button className="close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}

      {/* Rest of the page content */}
      <Header />
      <Aboutnaturopathy id="naturopathy" className="">About Naturopathy Content</Aboutnaturopathy>
      <Aboutus id="about-us" className="section">About Us Content</Aboutus>
      <Testimonial id="testimonial" className="section">Testimonials Content</Testimonial>
      <Services id="services" className="section">Services Offered Content</Services>
      <Benefits id="benefits" className="section">Benefits Content</Benefits>
      <Contactus id="contact-us" className="section">Contact Us Content</Contactus>
      <CTA id="cta-section" className="section">Call to Action Content</CTA>
    </div>
  );
}

export default App;
