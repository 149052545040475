import pic1 from '../assets/pic1.jpg';
import image1 from '../assets/Designer (1).jpeg'
const features = [
  { name: 'DIET AND NUTRITION', description: 'Customized dietary recommendations to support health and address specific conditions, often emphasizing natural foods & whole, unprocessed foods.' },
  { name: 'HERBAL MEDICINES', description: 'Utilizing plant-based remedies to support health and treat various ailments.' },
  { name: 'PHYSICAL HEALTH', description: 'Achieving robust physical health by utilizing natural resources.' },
  { name: 'LIFESTYLE COUNSELING', description: 'Guidance on lifestyle changes, including stress management, exercise, and sleep hygiene.' },
  { name: 'DETOXIFICATION', description: 'Supporting the body’s natural detoxification processes through dietary and lifestyle adjustments.' },
];

export default function Services() {
  return (
    <div id='services' className="bg-white py-12">
      <div className="container mx-auto px-6 sm:px-12 lg:px-24">
        <h2 className="text-3xl font-bold text-gray-900 mb-8 text-center">Common Practices in Core Naturopathy</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <dl className="space-y-6">
              {features.map((feature) => (
                <div key={feature.name} className="border-b border-green-500 pb-4">
                  <dt className="font-semibold text-gray-800 text-lg">{feature.name}</dt>
                  <dd className="mt-2 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          {/* Quad Image Gallery */}
          <div className="grid grid-cols-2 gap-4">
            <div className="relative">
              <img
                alt="Diet and Nutrition"
                src={pic1}
                className="h-full w-full object-cover rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="relative">
              <img
                alt="Herbal Medicines"
                src="https://sutherlandhouse.life/wp-content/uploads/2022/08/why-use-herbal-medicine.jpg"
                className="h-full w-full object-cover rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="relative">
              <img
                alt="Physical Health"
                src={image1}
                className="h-fit w-full object-cover rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
            <div className="relative">
              <img
                alt="Detoxification"
                src="https://cdn.pixabay.com/photo/2024/06/11/19/36/smoothie-8823898_1280.png"
                className="h-full w-auto object-cover rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
