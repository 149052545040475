import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons'
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowPathRoundedSquareIcon, CloudArrowUpIcon, FingerPrintIcon, IdentificationIcon, KeyIcon, LockClosedIcon, ShieldExclamationIcon, UserCircleIcon, UserPlusIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'THE  HEALING  POWER  OF  NATURE ',
    description:
      "Naturopathy believes in the body's innate ability to heal itself. With the right therapies, it enhances self-regulation and promotes disease reversal.",
    icon: ArrowPathRoundedSquareIcon,
  },
  {
    name: 'IDENTIFY & TREAT THE ROOT CAUSE',
    description:
      "Core Naturopathy targets the root causes of health issues, not just the symptoms, by assessing lifestyle, diet, and environmental factors.",
    icon: IdentificationIcon,
  },
  {
    name: 'FIRST DO NO HARM',
    description:
      ' Naturopathic Treatments are designed to be gentle and Absolutely  non-invasive, prioritizing methods that are safe and have no side effects. The Purpose is to support Holistic health .',
    icon: ShieldExclamationIcon,
  },
  {
    name: 'PRACTITIONER  AS  TEACHER',
    description:
      "Naturopathic practitioners empower patients to take charge of their own and their family's health by promoting healthy living and lifestyle changes.",
    icon: UserPlusIcon,
  },
  {
    name: 'TREAT THE PERSON AS WHOLE',
    description:
      'Core naturopathy considers the whole person—body, mind, and spirit—rather than focusing on isolated symptoms. This holistic approach aims to address Effectively  , the interconnected aspects of an individual’s health.',
    icon: UserCircleIcon,
  },
  {
    name: 'PREVENTION  IS THE KEY',
    description:
      '"Emphasizing prevention through healthy living, naturopathy promotes long-term wellness. Experience real results with natural remedies that work in harmony with your body."',
    icon: KeyIcon,
  },
]

export default function Example() {
  return (
    <div id="naturopathy" className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-green-600">Adopt Core Naturopathy to get Real & Long lasting health</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            About Core Naturopathy
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Core Naturopathy is an original & globally recognized, holistic healthcare approach that harnesses the body’s natural ability to heal. Combining traditional healing with modern science, it promotes wellness through natural therapies like diet, nutrition, detoxification, and lifestyle changes. Practiced worldwide, it addresses the root causes of illness to restore balance, support healing and Disease reversal.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((features) => (
              <div key={features.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-green-600">
                    <features.icon aria-hidden="true" className="h-6 w-6 text-white" />
                  </div>
                  {features.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{features.description}</dd>
              </div>
            ))}
          </dl>
        </div>
        
        </div>
      </div>
    
  )
}
