import { useState, useEffect } from 'react';
import image1 from '../assets/logo192.png';
import image from '../assets/test1.jpg';
import image2 from '../assets/test2.jpg';
import image3 from '../assets/test3.jpg';

export default function Example() {
  const testimonials = [
    {
      text: "“I am Chethana from Bangalore, have been living with type 1 diabetes for many years and was dependent on insulin and allopathic medicines. When I met the amazing team of Food Nutritionist and Naturopathy Coaches, Mr. Vipin Malhotra and Kumari Kusuma, they introduced me to a treatment plan solely based on food and lifestyle changes. Not only have I successfully managed my diabetes without medication, but I have also experienced significant improvements in my vision, a noticeable glow in my skin, and a reduction in urinary tract infections (UTIs). I am immensely grateful and happy to have regained control of my health through natural methods.”",
      name: "Chethana",
      role: "Client",
      image: image,  // No curly braces
      logo: image1,   // No curly braces
    },
    {
      text: "“I, veerabhadra, from Bangalore Karnataka, working as Manufacturing head in a Pharma company has been a diabetic from last two years, I was not sleeping more than 4 hours every day and getting tired very fast & was on Allopathic medicines. When I came across the Team of Food Nutritionist Core Naturopathy Coaches Mr Vipin Malhotra & kumari Kusuma, they gave me the treatment plan based on my food & lifestyle changes only. I have followed naturopathy protocol as per the guideline and now I am so happy that I am now free from Diabetes & medicines for life. I am sleeping well without break, my energy level is high which in turn increased my efficiency in the work so happy & thankful to them.”",
      name: "Veerabhadra",
      role: "Client",
      image: image2,  // No curly braces
      logo: image1,   // No curly braces
    },
    {
      text: "“My name is Mohini Nataraj,from Kushalanagar, I got in touch with Vipin sir & kusuma as referred by someone I know. I was suffering from Rheumatoid Arthritis for many years, Thyroid for 6 years and was taking 25 mg tablets. There was back pain, body pain, gastric and mental stress. I was in pain. They made a Naturopathic treatment plan for me with lifestyle changes. This helped me a lot. I desired this. Now I have more energy to work than before. The mind is satisfied. Sleep is good. It was difficult to work before. Now I can manage comfortably. Health has improved a lot with my lifestyle change. I am not taking medicines now. I am very much happy now. Thank you, I met you people”",
      name: "Mohini Nataraj",
      role: "Client",
      image: image3,  // No curly braces
      logo: image1,   // No curly braces
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const autoScroll = setInterval(() => {
      nextSlide();
    }, 10000);
    return () => clearInterval(autoScroll);
  }, []);

  return (
    <section id="testimonial" className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
      <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <img alt="" src={testimonials[currentIndex].logo} className="mx-auto h-12" />
        <figure className="mt-10">
          <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
            <p>{testimonials[currentIndex].text}</p>
          </blockquote>
          <figcaption className="mt-10">
            <img
              alt={testimonials[currentIndex].name}
              src={testimonials[currentIndex].image} // Ensure correct usage
              className="mx-auto h-20 w-20 rounded-full"
            />
            <div className="mt-4 flex items-center justify-center space-x-3 text-base">
              <div className="font-semibold text-gray-900">{testimonials[currentIndex].name}</div>
              <svg width={3} height={3} viewBox="0 0 2 2" aria-hidden="true" className="fill-gray-900">
                <circle r={1} cx={1} cy={1} />
              </svg>
              <div className="text-gray-600">{testimonials[currentIndex].role}</div>
            </div>
          </figcaption>
        </figure>

        {/* Navigation Arrows */}
        <div className="flex justify-between items-center mt-8">
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={prevSlide}
          >
            &#9664; {/* Left Arrow */}
          </button>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={nextSlide}
          >
            &#9654; {/* Right Arrow */}
          </button>
        </div>
      </div>
    </section>
  );
}
