'use client'

import { useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import image1 from '../assets/logo192.png';
import bgimage from '../assets/header bg.jpg';

const navigation = [ 
  { name: 'About Naturopathy', href: '#naturopathy' },
  { name: 'About Us', href: '#about-us' },
  { name: 'Services', href: '#services' },
  { name: 'Benefits', href: '#benefits' },
  { name: 'Contact Us', href: '#contact-us' }, // This should work now
];


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white scroll-smooth">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">V-Healers</span>
              <img
                alt=""
                src={[image1]}
                className="h-16 w-auto"
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          
        </nav>
        <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">V-Healers</span>
                <img
                  alt=""
                  src={[image1]}
                className="h-16 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        >
          
        </div>
        {/* Image Section */}
      <div className="flex flex-col justify-center items-center mt-40">
        <img
          alt=""
          src={image1}
          className="h-72 w-auto sm:justify-center"  // Increased image height and width auto adjusts
        />
      </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-32 lg:py-12">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative flex flex-col items-center justify-center rounded-full px-6 py-4 text-sm leading-6 text-gray-800 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
  <p className="text-center">
    What makes us different is the reversal we provide for lifestyle diseases by removing the 4 D's from anyone's life: Drugs, Diseases, Diagnosis, and Doctors, without any pills or supplements.
  </p>
  <a href="#contact-us" className="mt-2 font-semibold text-green-600">
    Get Consultation immediately <span aria-hidden="true">&rarr;</span>
  </a>
</div>


          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
             Core Naturopathy
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            Rediscover health through nature’s own remedies. Say no to any Medicines, antibiotics, vaccines and embrace total holistic Natural system. The world is turning to naturopathy—are you? Talk to us today and start your wellness journey.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#contact-us"
                className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Contact us
              </a>
              <a href="#about-us" className="text-sm font-semibold leading-6 text-gray-900">
                About Us <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        >
          
        </div>
      </div>
    </div>
  )
}
